import './App.css';
import FocusedPainting from './card/FocusedPaintingCard.tsx';
import originals from "./static/originals.json";
import masters from "./static/masters.json";
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import PaintingThumbnailRow from './card/PaintingThumbnailRow.tsx';

function App() {

  const [focusedPainting, setFocusedPainting] = useState(originals[0]);

  return (
    <div className="App">
      <header className="App-header">
        <Stack>
          <Stack sx={{ margin: "1vw" }} spacing="0.1vw">
            <Typography fontFamily="Playfair Display" fontSize="5vw" marginBottom="1vw">
              Shabnam Jabeen's Gallery
            </Typography>
            <Typography align="left" fontFamily={"Bitter"} fontStyle={"italic"} fontSize={"2vw"}>
                A showcase of original paintings and recreations of selected works from the masters
            </Typography>
          </Stack>
    
        <Stack spacing="1vw">
          <FocusedPainting painting={focusedPainting} />

          <Stack direction="row">
            <Typography fontFamily="Playfair Display" fontSize="4vw">
              {/* My Originals */}
            </Typography>
          </Stack>
          <PaintingThumbnailRow paintings={originals} setFocusedPainting={setFocusedPainting} />

          <Stack direction="row">
            <Typography fontFamily="Playfair Display" fontSize="4vw">
              My Masters <br/>
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography align="left" fontFamily={"Bitter"} fontStyle={"italic"} fontSize={"0.8vw"}>
              Without any professional training in arts but with a deep love for painting, I proudly present My Masters - 
              a collage of paintings after some of the artists who inspire me the most. <br/> 
              With each attempt at replicating their masterpieces, I am humbled by their unparalleled talents 
              and the vastness of their creative achievements.<br/> 
            </Typography>
          </Stack>
          <PaintingThumbnailRow paintings={masters} setFocusedPainting={setFocusedPainting} />
          <br/><br/><br/>
        </Stack>
      </Stack>
      </header>
    </div>
  );
}

export default App;
